/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { GlobalWrapper } from '@global/styles/grid';
import { getTrainingData } from 'services/integrations';
import { PATHS } from '@global/constants/urls';
import { WebinarBlogBackHomepage } from 'template/styles';
import { BackContainer, CalendarContainer } from './fullCalendarComponent.styles';
import { IFullCalendarProps } from './fullCalendarComponent.types';

const FullCalendarComponent = () => {
  const [renderEvents, setRenderEvents] = useState<IFullCalendarProps[]>([]);
  const handlerEvents = async () => {
    const allEvents = await getTrainingData();
    const parsedEvent: IFullCalendarProps[] = [];
    allEvents.forEach((event) => {
      event.occurrences.forEach((occurrence) => {
        const eventData: IFullCalendarProps = {
          title: event.topic,
          url: event.registration_url,
          start: '',
          end: '',
        };
        const startEvent = new Date(occurrence.start_time);
        eventData.start = startEvent.toISOString();
        // calculate end time
        startEvent.setMinutes(startEvent.getMinutes() + occurrence.duration);
        eventData.end = startEvent.toISOString();
        parsedEvent.push(eventData);
      });
    });
    setRenderEvents(parsedEvent);
  };

  useEffect(() => {
    handlerEvents();
  }, []);

  return (
    <GlobalWrapper>
      <BackContainer>
        <WebinarBlogBackHomepage href={PATHS.WEBINAR}>{`<`} Back</WebinarBlogBackHomepage>
      </BackContainer>
      <CalendarContainer>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
          initialView="dayGridMonth"
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
          }}
          weekends
          events={renderEvents}
          eventTimeFormat={{
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          }}
        />
      </CalendarContainer>
    </GlobalWrapper>
  );
};

export default FullCalendarComponent;
